import React from 'react';

const Terms = () => {
  return (
    <>
      <article>
        <h1 style={{ textAlign: 'center' }}>使用条款和条件</h1>
        <p>使用我们的平台即表示您同意以下条款和条件。</p>
        
        <h3>1. 兑换码和付款</h3>
        <p>您可以购买兑换码以访问我们的API服务。注意额度有效期为3个月，请按需购买。</p>

        <h3>2. 无退款</h3>
        <p>由于我们服务的性质，我们不提供任何形式的退款。</p>

        <h3>3. 价格变动</h3>
        <p>我们保留随时修改我们的定价和访问政策的权利。</p>

        <h3>4. 电子邮件沟通</h3>
        <p>我们可能会通过电子邮件与您联系以进行更新或营销。您可以随时选择退出。</p>

        <h3>5. 条款更新</h3>
        <p>这些条款可能会根据我们的判断进行更新。继续使用即意味着同意变更。</p>

        <h3>6. API使用限制</h3>
        <p>您不得使用我们的API违反任何法律、伤害或剥削未成年人、用于垃圾邮件或未经请求的广告，或冒充任何实体或个人。</p>

        <h3>7. 服务可用性</h3>
        <p>我们按“原样”提供我们的服务，并不保证正常运行时间或无误操作。</p>

        <h3>8. 数据保护</h3>
        <p>我们不收集或使用用户与我们的API互动产生的任何数据。我们不负责您如何管理通过我们的API访问的数据。</p>

        <h3>9. 责任限制</h3>
        <p>我们对由使用或滥用我们的服务产生的任何损害或问题不承担责任。</p>

        <h3>10. 法律风险</h3>
        <p>请务必遵守中华人民共和国法律法规政策，自觉遵守《生成式人工智能服务管理暂行办法》</p>

        <h3>11. 联系方式</h3>
        <p>如有疑问，请通过以下电子邮件与我们联系：support@openkey.cloud。</p>
      </article>
    </>
  );
};

export default Terms;